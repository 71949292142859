<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 19"
    width="32"
  >
    <path d="M10 0A9.5 9.5 0 0 0 .7 9.8v9H4A1.8 1.8 0 0 0 6 17v-4.5a1.8 1.8 0 0 0-1.9-1.8H2v-1a8.2 8.2 0 0 1 8-8.3 8.2 8.2 0 0 1 8 8.3v1h-2.1a1.8 1.8 0 0 0-1.9 1.8V17a1.8 1.8 0 0 0 1.9 1.7h3.4v-9A9.5 9.5 0 0 0 10 .1ZM4.1 12a.5.5 0 0 1 .6.5V17a.5.5 0 0 1-.6.4H2v-5.3h2.1Zm11.2 5v-4.5a.5.5 0 0 1 .6-.4H18v5.3h-2.1a.5.5 0 0 1-.6-.4Z" />
  </svg>
</template>
